.commissions-grid {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 100px;

  @include media("<=860px") {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

}

.commission-card {
  border-radius: 10px;
  background: $darkBox;
  flex: 1;
  text-align: center;
  height: 375px;
  font-size: 18px;
  padding-bottom: 65px;
  position: relative;

  @include media("<=860px") {
    margin-bottom: 60px;
    width: 100%;
    padding-bottom: 30px;
    font-size: 16px;

    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: 3;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    img {
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    color: $secondaryColor;
    margin-bottom: 15px;
    line-height: 1;
    font-weight: bold;
  }

  &__text {
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    max-width: 265px;
  }

  &:nth-child(2) {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
    transform: scale(1.15);
    position: relative;
    z-index: 2;

    @include media("<=860px") {
      transform: scale(1);

    }
  }

  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    min-width: 220px;

    @include media("<=860px") {
      transform: none;
      left: 0;
      bottom: 0;
      position: relative;
      margin-top: 15px;
    }
  }
}

.commission-page {
  &__card {
    width: 770px;
    max-width: 95%;
    margin: 0 auto;
    margin-top: 80px;
    border-radius: 10px;
    padding: 20px 30px;
    background-color: $darkBox;
    color: #fff;

    @include media("<568px") {
      padding: 20px 10px;
    }
  }

  &__icon {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
  }

  &__list {
    list-style: none;
    padding: 0;

    li {
      + li {
        margin-top: 10px;
      }

      position: relative;
      padding-left: 20px;
      font-size: 16px;
      line-height: 1.5;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primaryColor;
      }
    }
  }

  .btn {
    width: 210px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
  }
   .ant-typography {
     text-align: center;
   }
}