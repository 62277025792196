.footer {
  left: 0;
  bottom: 0;
  height: 82px;
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<1200px') {
      flex-direction: column;
      text-align: center;
    }
  }

  &__info {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: $white;
  }

  &__menu {
    display: flex;
    list-style: none;
    padding: 10px 0;
    margin: 0;
    gap: 10px;

    @include media('<1200px') {
      flex-direction: column;
    }
    a {
      color: #fff;
      opacity: .75;
      font-size: 12px;
      transition: all .2s ease;
      &.active {
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}