.social-contacts {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 999;
  opacity: .65;
  transition: all .2s ease;

  @include media("<568px") {
    right: 10px;
  }

  &:hover {
    opacity: 1;
  }


  li {
    +li {
      margin-top: 10px;
    }
  }
  a {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: $darkBox;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(#FFC328, .2);
    box-shadow: 0 0 15px -8px #FFC328;
    transition: all .2s ease;

    @include media("<568px") {
      width: 45px;
      height: 45px;

      img {
        max-width: 35px;
      }
    }
    &:hover {
      background: lighten($darkBox, 10%);
    }
    &:active {
      transform: scale(.9);
    }
  }
}