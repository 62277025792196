.main-banner {
  z-index: 1;
  position: relative;
  margin-top: -105px;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: 20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            width: 45px;
            background: $secondaryColor;
            border-radius: 21px;
          }
        }

        button {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $alwaysWhite;
          padding: 4px;

          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  position: relative;
  padding-top: 100px;

  @include media("<568px") {
    padding-left: 2vw;
    padding-top: 10vw;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($alwaysBlack, .3);
      z-index: 3;
      opacity: 0;

      @include media("<568px") {
        opacity: 1;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    height: 600px;
    position: relative;
    z-index: 5;
    padding-top: 105px;

    @include media("<568px") {
      height: auto;
      min-height: 400px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 52px;
    line-height: 1.3;
    margin-bottom: 15px;
    color: $secondaryColor;
    font-family: $titleFont;
    max-width: 700px;

    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    color: rgba(250,250,250, .9);
    max-width: 600px;

    @include media("<568px") {
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: 15px;
    line-height: 33px;

  }
}